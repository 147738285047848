@import url("https://fonts.googleapis.com/css2?family=Public+Sans:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import 'https://code.ionicframework.com/ionicons/2.0.1/css/ionicons.min.css';
@import 'https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.1.1/css/all.min.css';
@import '../../../node_modules/react-quill/dist/quill.snow.css';
html {
  scroll-behavior: smooth;
}

.mainContainer {
  display: grid;
  place-items: center;
  height: 90vh;
}

.container {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.container p {
  position: absolute;
  color: #000;
  font-size: 16px;
  bottom: 0px;
}

.container .ring {
  position: relative;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  margin: -30px;
  border: 4px solid transparent;
  border-top: 4px solid #E91E63;
  animation: rotate 4s linear infinite;
}

.container .ring:nth-child(2) {
  border-top-color: #2196F3;
  animation: rotate2 4s linear infinite;
  animation-delay: -2s;
}

.container .ring:nth-child(3) {
  border-top-color: #4CAF50;
  animation: rotate3 4s linear infinite;
  animation-delay: 2s;
  position: absolute;
  top: -66.66px;
}

.container .ring::before {
  content: "";
  position: absolute;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  top: 12px;
  right: 12px;
  background-color: #E91E63;
  box-shadow: 0 0 0 5px rgba(233, 30, 99, 0.2), 0 0 0 10px rgba(233, 30, 99, 0.1333333333), 0 0 0 16px rgba(233, 30, 99, 0.0666666667), 0 0 20px #E91E63, 0 0 50px #E91E63;
}

.container .ring:nth-child(2)::before {
  bottom: 12px;
  left: 12px;
  background-color: #2196F3;
  box-shadow: 0 0 0 5px rgba(33, 150, 243, 0.2), 0 0 0 10px rgba(33, 150, 243, 0.1333333333), 0 0 0 16px rgba(33, 150, 243, 0.0666666667), 0 0 20px #2196F3, 0 0 50px #2196F3;
}

.container .ring:nth-child(3)::before {
  background-color: #4CAF50;
  box-shadow: 0 0 0 5px rgba(76, 175, 80, 0.2), 0 0 0 10px rgba(76, 175, 80, 0.1333333333), 0 0 0 16px rgba(76, 175, 80, 0.0666666667), 0 0 20px #4CAF50, 0 0 50px #4CAF50;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes rotate2 {
  0% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
@keyframes rotate3 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
body {
  font-family: "Public Sans", sans-serif;
  line-height: 1.7em;
  font-weight: 400;
  background: #fff;
  overflow-x: hidden;
}

::-webkit-scrollbar {
  width: 0;
}

.mb-80 {
  margin-bottom: 80px;
}

.mt-80 {
  margin-top: 80px;
}

.ptb-80 {
  padding: 80px 0;
}

.mtb-80 {
  margin: 80px 0;
}

.pt-80 {
  padding-top: 80px;
}

.pb-80 {
  padding-bottom: 80px;
}

.mb-100 {
  margin-bottom: 100px;
}

.mt-100 {
  margin-top: 100px;
}

.ptb-100 {
  padding: 100px 0;
}

.mtb-100 {
  margin: 100px 0;
}

.pt-100 {
  padding-top: 100px;
}

.pb-100 {
  padding-bottom: 100px;
}

.fs-12 {
  font-size: 12px !important;
}

.fs-14 {
  font-size: 14px !important;
}

.pointer {
  cursor: pointer;
}

.box-shadow {
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1882352941);
}
.box-shadow-2 {
  box-shadow: 0 0 15px rgba(255, 255, 255, 0.4);
}

.box-shadow-hover:hover {
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1882352941);
  transition: all 0.5s;
}

.hover-rounded:hover {
  border-radius: 10px;
  transition: all 0.5s;
}

.right-0 {
  right: 0;
}

.left-0 {
  left: 0;
}

.pt-10 {
  padding-top: 10pc;
}

.mt-10 {
  margin-top: 10pc;
}

.btn:focus {
  box-shadow: none;
}

.box-shadow-hover:hover {
  box-shadow: 0px 10px 20px rgba(108, 39, 255, 0.3019607843);
  transition: all 0.5s;
}

.btn {
  padding: 10px 30px;
  font-weight: 500 !important;
  transition: all 0.5s;
  font-size: 14px;
  white-space: nowrap;
}
.btn-1 {
  border: 0;
  font-size: 14px;
  font-weight: 400 !important;
  background-color: #D68594;
  color: #fff;
}

.btn-1:focus {
  background-color: #D68594;
  color: #fff;
}

.btn-1:hover {
  transition: all 0.5s;
  background-color: #D68594;
  color: #fff;
  box-shadow: 0px 10px 20px rgba(108, 39, 255, 0.3019607843);
}
.btn-2 {
  border: 0;
  font-size: 14px;
  font-weight: 400 !important;
  background-color: white;
  color: black;
  border: solid 1px #D68594;
}
.btn-2:hover {
  transition: all 0.5s;
  background-color: #D68594;
  color: #fff;
  box-shadow: 0px 10px 20px rgba(108, 39, 255, 0.3019607843);
}
.btn-green {
  background: rgba(75, 207, 144, 0.1019607843);
  font-size: 13px !important;
  font-weight: 500 !important;
  color: #28a745 !important;
  border: 0;
  display: inline-block;
  border-radius: 10px;
  padding: 7px 21px;
  white-space: nowrap;
  line-height: 1.2;
  text-transform: none;
}
.btn-green:hover {
  background: rgba(75, 207, 144, 0.1019607843) !important;
}
.btn-red {
  background: rgba(255, 109, 104, 0.1019607843);
  font-size: 13px !important;
  font-weight: 500 !important;
  color: #dc3545 !important;
  border: 0;
  display: inline-block;
  border-radius: 10px;
  padding: 7px 21px;
  white-space: nowrap;
  line-height: 1.2;
  text-transform: none;
}
.btn-red:hover {
  background: rgba(255, 109, 104, 0.1019607843);
}

p:last-child {
  margin-bottom: 0;
}

header,
main,
footer {
  padding: 30px 30px;
}

main {
  padding-top: 30px;
}

.fw-600 {
  font-weight: 600;
}

.red {
  color: red;
}

.green {
  color: #54DA8A;
}

.blue-1 {
  color: #757474 !important;
}

.blue-1-bg {
  background-color: #757474 !important;
}

.blue-2-bg {
  background-color: #757fac !important;
}

.blue-2 {
  color: #757fac !important;
}

.gray-bg {
  background-color: #F7F7FF !important;
}

a:hover {
  color: #757474;
  transition: all 0.5s;
}

ul {
  margin: 0;
  padding: 0;
}
ul.list-circle li {
  list-style-type: circle !important;
  list-style-position: inside !important;
}
ul li {
  list-style: none;
}

.btn-sm {
  font-size: 0.875rem !important;
  padding: 7px 22px !important;
  border-radius: 0.2rem;
}

.main-logo {
  height: auto;
  -o-object-fit: contain;
     object-fit: contain;
  transition: all 0.5s;
}

.img-fluid {
  height: 100%;
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}

.title-section {
  margin-bottom: 2pc;
}

img.client-img {
  height: 80px;
  -o-object-fit: contain;
     object-fit: contain;
}

a[data-rel^=lightcase] {
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 3;
  top: 0;
}
a[data-rel^=lightcase] i {
  height: 75px;
  width: 75px;
  background-color: #fff;
  border-radius: 50%;
  line-height: 75px;
  text-align: center;
  color: #d35356;
  font-size: 45px;
}

.heading {
  text-transform: capitalize;
}
.heading + h5 {
  margin-top: 2pc;
}

.container-fluid-padding {
  padding: 0 8pc;
}

.icon {
  background-color: #fff;
  height: 100px;
  width: 100px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1254901961);
  margin-bottom: 1pc;
}
.icon img {
  width: 45px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #171717;
}

[class*=swiper-button] {
  background-color: #fff !important;
  color: #d35356 !important;
  height: 50px !important;
  width: 50px !important;
  border-radius: 50% !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1882352941) !important;
}
[class*=swiper-button]:after {
  font-size: 22px !important;
  font-weight: 900 !important;
}

.social-links img {
  width: 28px;
  background-color: #fff;
  border-radius: 5px;
}

a {
  text-decoration: none !important;
}

.password-input {
  position: relative;
}
.password-input > i {
  height: 100%;
  width: auto;
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 1pc;
  cursor: pointer;
}

@keyframes move {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(0);
  }
  75% {
    transform: translateY(50px);
  }
  100% {
    transform: translateY(0);
  }
}
@keyframes box-shadow {
  0% {
    box-shadow: 0 0 3px 10px rgba(255, 255, 255, 0.4745098039);
  }
  50% {
    box-shadow: 0 0 3px 16px rgba(255, 255, 255, 0.3019607843);
  }
  75% {
    box-shadow: 0 0 3px 22px rgba(255, 255, 255, 0.2);
  }
  100% {
    box-shadow: 0 0 3px 28px rgba(255, 255, 255, 0.0196078431);
  }
}
@keyframes animation-pulse {
  0% {
    -webkit-transform: scale(0.1, 0.1);
    opacity: 0;
  }
  60% {
    -webkit-transform: scale(0.1, 0.1);
    opacity: 0;
  }
  65% {
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1.2, 1.2);
    opacity: 0;
  }
}
.play-btn {
  color: #e66541;
  height: 80px;
  width: 80px;
  line-height: 80px;
  font-size: 48px;
  background-color: rgba(255, 255, 255, 0.7098039216);
  border-radius: 50%;
  text-align: center;
  padding-left: 7px;
  border: 0;
  animation: box-shadow 1.5s ease 0s infinite;
}

.form .position-relative .form-control {
  padding-left: 30px;
}
.form [class*=col-] {
  margin-bottom: 10px;
}
.form [class*=col-]:last-child {
  margin-bottom: 0;
}
.form .form-check:not(.form-check-inline) {
  display: flex;
  gap: 10px;
  align-items: center;
}
.form .form-check.form-check-inline .form-check-input {
  margin-right: 10px;
}
.form .form-check input[type=checkbox] {
  background-size: 16px auto !important;
  border-radius: 0;
  -webkit-appearance: none;
  cursor: pointer;
  transition: all 0.5s;
}
.form .form-check input[type=checkbox].form-check-input:focus {
  border-color: rgba(0, 0, 0, 0.2509803922);
  box-shadow: none;
}
.form .form-check input[type=checkbox]:checked {
  background-color: #000;
  border-color: #000;
  transition: all 0.5s;
}
.form .css-1s2u09g-control {
  height: 40px;
  font-size: 14px;
  border-color: #ced4da;
}
.form .css-1s2u09g-control:focus {
  box-shadow: none !important;
  border-color: #ced4da !important;
  font-size: 14px !important;
  transition: all 0.5s;
}
.form .css-1s2u09g-control::-moz-placeholder {
  color: #000;
  text-transform: capitalize;
}
.form .css-1s2u09g-control::placeholder {
  color: #000;
  text-transform: capitalize;
}
.form .css-1s2u09g-control:hover {
  border-color: #ced4da;
}
.form .css-1s2u09g-control .css-g1d714-ValueContainer {
  padding-top: 0;
  padding-bottom: 0;
}
.form .css-1s2u09g-control .css-12jo7m5 {
  line-height: 20px;
}
.form textarea.form-control {
  height: 102px !important;
}
.form .form-control {
  height: 40px;
  font-size: 14px;
}
.form .form-control:focus {
  border-color: #ced4da;
  box-shadow: none;
  transition: all 0.5s;
}
.form .form-control:focus::-moz-placeholder {
  opacity: 0;
  -moz-transition: all 0.5s;
  transition: all 0.5s;
}
.form .form-control:focus::placeholder {
  opacity: 0;
  transition: all 0.5s;
}
.form .form-control::-moz-placeholder {
  color: #000;
  text-transform: capitalize;
}
.form .form-control::placeholder {
  color: #000;
  text-transform: capitalize;
}
.form .form-control[type=file] + a {
  width: 125px;
  pointer-events: none;
  padding-top: 0;
  padding-bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.form .form-control[readonly] {
  background: white;
}
.form label {
  font-size: 13px;
  color: rgba(65, 80, 148, 0.9490196078);
  margin: 0;
  line-height: 20px;
}
.form .ql-editor {
  height: 100px !important;
}

.makeChange .form-control {
  height: auto;
}

td,
th {
  padding: 8px 1.5pc !important;
}

.scale-img {
  overflow: hidden;
}
.scale-img img {
  transition: all 0.5s linear;
}
.scale-img img:hover {
  transform: scale(1.2) rotate(10deg);
  transition: all 0.5s linear;
}

@media only screen and (min-width: 1200px) {
  .column-5 {
    flex-basis: 20%;
  }
}
ul.reviews-star li {
  color: #FFCE31;
  font-size: 27px;
}

.dashboard-box {
  background-color: #fff;
  border-radius: 10px;
  padding: 1.5pc 2pc;
}
.dashboard-table .rdt_TableRow,
.dashboard-table .rdt_TableHeadRow {
  border-bottom: 1px solid #000 !important;
}
.dashboard-table .rdt_TableCell img {
  width: auto;
  height: 55px;
  -o-object-fit: contain;
     object-fit: contain;
}
.dashboard-table .rdt_TableCell,
.dashboard-table .rdt_TableCol {
  padding: 16px;
  line-height: 1.9;
}
.dashboard-table .rdt_TableRow {
  color: #828bb2;
  font-size: 14px;
}
.dashboard-table .rdt_TableRow a {
  color: #828bb2;
}
.dashboard-table .rdt_TableHeadRow {
  color: #000;
  text-transform: uppercase;
}
.dashboard-chart canvas {
  width: auto !important;
  margin: 0 auto;
  height: 300px !important;
  -o-object-fit: contain;
     object-fit: contain;
}

.search-field .input-group {
  color: #000;
  font-size: 13px;
  height: 40px;
  width: 100%;
  border: 0;
  border-bottom: 1px solid #000;
  background: transparent;
  padding: 10px;
  align-items: center;
}
.search-field .input-group .form-control {
  border: 0 !important;
  padding: 0;
  height: auto !important;
  background-color: transparent;
}
.search-field .input-group .form-control::-moz-placeholder {
  color: #425191;
}
.search-field .input-group .form-control::placeholder {
  color: #425191;
}
.search-field .input-group-text {
  background-color: transparent;
  border: 0;
  padding: 0 10px 0 0;
  height: 100%;
}
.search-field .input-group-text i {
  font-size: 23px;
}

.add-delete-icon li {
  height: 35px;
  width: 35px;
  background-color: #f0f0f0;
  border-radius: 50%;
  transition: all 0.5s;
  cursor: pointer;
}
.add-delete-icon li:hover {
  background-color: #000;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1882352941);
  transition: all 0.5s;
}
.add-delete-icon li:hover a,
.add-delete-icon li:hover button {
  color: #fff !important;
}
.add-delete-icon li a,
.add-delete-icon li button {
  color: #000 !important;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: transparent;
}

.modal-box {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-y: scroll;
}
.modal-container {
  background-color: #fff;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1254901961);
  border-radius: 15px;
  height: -moz-max-content;
  height: max-content;
}
.modal-header {
  position: relative;
  border-radius: 15px 15px 0px 0px;
  background-color: #D68392;
  color: white !important;
}
.modal-header h5,
.modal-header h4 {
  color: #fff;
  margin-bottom: 0;
}
.modal-body, .modal-header {
  padding: 20px 30px;
}
.modal-close {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  padding: 0 1pc;
}
.modal-close i {
  font-size: 22px;
}

.newpaddingtoplefgt {
  padding: 27px 22px 20px 22px;
}

.heading700 {
  color: #000 !important;
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 0;
}

.dropdownmenui .css-1s2u09g-control {
  border: 1px solid rgba(145, 158, 171, 0.32);
  border-radius: 8px;
  padding: 5px 0;
  height: 60px;
}

.dropdownmenui .css-1s2u09g-control {
  height: 60px;
}

.font6updateadmin {
  color: #212B36 !important;
  font-weight: 600;
  font-size: 18px;
}

.form-control.muiinput .Mui-focused {
  color: #919EAB;
  background-color: #fff !important;
}

#outlined-basic::-moz-placeholder {
  color: #ff0000;
}

#outlined-basic::placeholder {
  color: #ff0000;
}

.MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-formControl:hover .MuiOutlinedInput-notchedOutline {
  border-color: rgba(145, 158, 171, 0.5) !important;
}

.iconclassmain {
  position: absolute;
  right: 16px;
  top: 18px;
  color: #919EAB;
  font-size: 18px;
}

.padding_search_icon .css-ce2e25-MuiInputBase-root-MuiOutlinedInput-root {
  padding-right: 35px;
}

.me-4.btn.btn-1.btn-outline {
  border: 1px solid rgba(255, 72, 66, 0.48);
  border-radius: 8px;
  background-color: white;
  color: #FF4842;
  font-weight: 700 !important;
}

.product-category .form label {
  color: #919EAB !important;
}

#sidebar {
  position: fixed;
  overflow: auto;
  width: 100%;
  height: 100vh;
  background-color: #fff;
  border: 1px dashed rgba(145, 158, 171, 0.24);
}
#sidebar::-webkit-scrollbar {
  width: 5px;
  background: #b9b6b6;
}
#sidebar::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}
#sidebar .main-logo {
  padding: 26px;
  margin-bottom: 20px;
  display: grid;
  place-items: center;
}
#sidebar .main-logo img {
  width: 100%;
}
#sidebar ul.sidebar-menu li {
  margin: 0px 10px;
}
#sidebar ul.sidebar-menu li [id*=sidebar_item] a {
  padding-left: 4pc;
}
#sidebar ul.sidebar-menu li a {
  padding: 9px 7px 9px 29px;
  display: flex;
  align-items: center;
  gap: 20px;
  font-size: 12px;
  font-weight: 400;
  color: black;
  position: relative;
  background: transparent;
  text-transform: uppercase;
  position: relative;
  border-radius: 8px;
}
#sidebar ul.sidebar-menu li a + .collapse, #sidebar ul.sidebar-menu li a + .collapsing {
  background-color: rgba(255, 255, 255, 0.231372549);
}
#sidebar ul.sidebar-menu li a.active {
  background-color: #ffe7d9;
  border-radius: 8px;
  color: #000;
}
#sidebar ul.sidebar-menu li a.active:before {
  content: "";
  transition: all 0.5s;
}
#sidebar ul.sidebar-menu li a.active p {
  color: #B72136 !important;
  font-weight: 700;
}
#sidebar ul.sidebar-menu li a:hover {
  background-color: #ffe7d9;
  color: black !important;
}
#sidebar ul.sidebar-menu li a:before {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 5px;
  transition: all 0.5s;
}
#sidebar ul.sidebar-menu li a:hover:before {
  content: "";
  transition: all 0.5s;
}
#sidebar ul.sidebar-menu li a p {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  font-family: "Public Sans", sans-serif;
  color: #637381;
  font-size: 14px;
  font-weight: 600;
}
#sidebar ul.sidebar-menu li a p i {
  font-size: 11px;
}
#sidebar ul.sidebar-menu li a > i {
  font-size: 17px;
}

.sidebar-open {
  width: 16vw !important;
  transition: all ease-in-out 0.5s;
  border: 1px dashed rgba(145, 158, 171, 0.24);
}

.sidebar-closed {
  width: 5vw !important;
  transition: all ease-in-out 0.5s;
  border: 1px dashed rgba(145, 158, 171, 0.24);
}

#sidebar {
  position: fixed;
  overflow: auto;
  width: 100%;
  height: 100vh;
  background-color: #fff;
  border: 1px dashed rgba(145, 158, 171, 0.24);
}
#sidebar::-webkit-scrollbar {
  width: 5px;
  background: #b9b6b6;
}
#sidebar::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}
#sidebar .main-logo-reverse {
  padding: 26px;
  margin-bottom: 10px;
  margin-top: 50px;
  transition: all ease-in-out 0.5s;
  transform: rotate(-90deg);
}
#sidebar .main-logo-reverse img {
  width: 100px;
}
#sidebar ul.sidebar-menu li {
  margin: 0px 23px 0px 10px;
}
#sidebar ul.sidebar-menu li [id*=sidebar_item] a {
  padding-left: 35px !important;
}
#sidebar ul.sidebar-menu li a {
  padding: 9px 7px 9px 20px;
  display: flex;
  align-items: center;
  gap: 20px;
  font-size: 12px;
  font-weight: 400;
  color: black;
  position: relative;
  background: transparent;
  text-transform: uppercase;
  position: relative;
  border-radius: 8px;
}
#sidebar ul.sidebar-menu li a + .collapse, #sidebar ul.sidebar-menu li a + .collapsing {
  background-color: rgba(255, 255, 255, 0.231372549);
}
#sidebar ul.sidebar-menu li a.active {
  background-color: #ffe7d9;
  border-radius: 8px;
  color: #000;
}
#sidebar ul.sidebar-menu li a.active:before {
  content: "";
  transition: all 0.5s;
}
#sidebar ul.sidebar-menu li a.active p {
  color: #B72136 !important;
  font-weight: 700;
}
#sidebar ul.sidebar-menu li a:hover {
  background-color: #ffe7d9;
  color: black !important;
}
#sidebar ul.sidebar-menu li a:before {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 5px;
  transition: all 0.5s;
}
#sidebar ul.sidebar-menu li a:hover:before {
  content: "";
  transition: all 0.5s;
}
#sidebar ul.sidebar-menu li a p {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  font-family: "Public Sans", sans-serif;
  color: #637381;
  font-size: 14px;
  font-weight: 600;
}
#sidebar ul.sidebar-menu li a p i {
  font-size: 11px;
}
#sidebar ul.sidebar-menu li a > i {
  font-size: 17px;
}

.userDetailContainer table,
.userDetailContainer tr,
.userDetailContainer th,
.userDetailContainer td {
  font-size: 14px;
}
.userDetailContainer .educationContainer tr,
.userDetailContainer .educationContainer td {
  width: 30%;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.header .collaspe_icon i,
.header .notification-bell i {
  font-size: 23px;
  cursor: pointer;
}
.header .user-profile img {
  padding: 2px;
  background-color: #000;
  box-shadow: 4px 4px 15px #ccc;
  height: 45px;
  width: 45px;
}
.header .user-profile:hover .user-profile-box {
  visibility: visible;
  opacity: 1;
  transition: all 0.5s;
}
.header .user-profile-box {
  width: 200px;
  background-color: white;
}
.header .user-profile-box ul {
  border-top: 1px solid rgba(0, 0, 0, 0.5019607843);
}
.header .user-profile-box ul li a {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 7px;
  margin-bottom: 15px;
}
.header .user-profile-box ul li a:hover {
  color: #000;
}
.header .user-profile-box ul li .ion-share {
  transform: scaleX(-1);
}
.header .user-profile-box ul li:last-child a {
  margin-bottom: 0;
}
.header .user-profile-box p,
.header .user-profile-box li,
.header .user-profile-box i,
.header .user-profile-box a {
  color: #000 !important;
  text-align: right;
}
.header .user-profile-box p:first-child {
  font-size: 12px;
  color: rgba(255, 255, 255, 0.5019607843);
}
.header .user-profile-box i {
  font-size: 20px;
}
.header .user-profile-box p,
.header .user-profile-box li {
  font-size: 14px;
}
.header .notification-bell i + span.MuiBadge-badge {
  background: black;
  color: #000;
  animation: box-shadow 3.5s ease-out infinite;
}
.header .notification-bell:hover .notification-box {
  visibility: visible;
  opacity: 1;
  transition: all 0.5s;
}
.header .notification-box-content {
  height: 300px;
  display: flex;
  flex-direction: column;
  overflow: hidden scroll;
}
.header .notification-box-header {
  border-radius: 10px 10px 0 0;
}
.header .notification-box,
.header .user-profile-box {
  border-radius: 10px;
  position: absolute;
  right: -2pc;
  z-index: 999;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2509803922);
  visibility: hidden;
  opacity: 0;
  transition: all 0.5s;
}

.dashboard-filter {
  display: flex;
  gap: 20px;
}
.dashboard-filter li .filtering {
  color: #000;
  padding: 5px 20px;
  background: #fff;
  border: 1px solid #000;
  border-radius: 30px;
  font-size: 12px;
  text-align: center;
  line-height: 20px;
  font-weight: 500;
}
.dashboard-filter li .filtering.active {
  color: #fff;
  background: #000;
}
.dashboard-filter li .filtering:hover {
  color: #fff !important;
  background: #000;
  transition: all 0.5s;
}
.dashboard-pills {
  display: flex;
  gap: 20px;
}
.dashboard-pills li .nav-link {
  color: #000;
  padding: 5px 20px;
  background: #fff;
  border: 1px solid #000;
  border-radius: 30px;
  font-size: 12px;
  text-align: center;
  line-height: 20px;
  font-weight: 500;
}
.dashboard-pills li .nav-link.active {
  color: #fff;
  background: #000;
}
.dashboard-pills li .nav-link:hover {
  color: #fff !important;
  background: #000;
  transition: all 0.5s;
}
.dashboard-summary {
  margin-bottom: 1pc;
  border: 1px solid transparent;
  background-color: #fff;
  box-shadow: 0px 0px 2px rgba(145, 158, 171, 0.2), 0px 12px 24px -4px rgba(145, 158, 171, 0.12);
  border-radius: 16px;
}
.dashboard-summary h5 {
  margin-bottom: 1pc;
}
.dashboard-summary:hover {
  background-position: right center;
  transition: all 0.5s;
}

.dragable-menu li {
  background-color: #f7f7f7;
  padding: 5px 1pc;
  margin-bottom: 1pc;
  cursor: move;
  border: 1px solid lightgray;
  font-size: 14px;
}

.customer-profile.bg-light {
  height: 225px;
}
.customer-profile img {
  height: 165px;
  width: 165px;
}
.customer-profile li {
  display: flex;
  gap: 18px;
}
.customer-profile li > span {
  width: 175px;
  display: flex;
  justify-content: space-between;
}

.modal-body .customer-profile li {
  border-bottom: 1px solid #afafaf;
  padding-bottom: 1pc;
  margin-bottom: 1pc;
}
.modal-body .customer-profile li:last-child {
  border: 0;
  margin: 0;
  padding: 0;
}
.modal-body .show-product .product-main-image {
  text-align: center;
}
.modal-body .show-product .product-main-image img {
  height: 100%;
  width: 300px;
  -o-object-fit: contain;
     object-fit: contain;
}
.modal-body .show-product .product-main-image + h5 + ul img {
  height: 100px;
  width: auto;
  -o-object-fit: contain;
     object-fit: contain;
  border: 1px solid #cbc7c7;
}
.modal-body .show-product .customer-profile li {
  padding-bottom: 8px;
  margin-bottom: 8px;
}

.topbgcolor {
  background-color: #fff;
}

.breadcumarea {
  padding: 20px 0;
}
.breadcumarea h4 {
  font-weight: 700;
  margin-bottom: 0;
  font-size: 24px;
}
.breadcumarea ul {
  display: flex;
  gap: 20px;
}
.breadcumarea ul li {
  position: relative;
  color: #212B36;
}
.breadcumarea ul li::after {
  content: "";
  position: absolute;
  top: 12px;
  left: -13px;
  height: 6px;
  width: 6px;
  border-radius: 20px;
  background: #919EAB;
  transition: all 0.5s;
}
.breadcumarea ul li:first-child::after {
  content: none;
  display: none;
}
.breadcumarea ul li.active {
  color: #919EAB;
  font-size: 14px;
}

.borderbotm {
  border-bottom: 1px solid #DFE3E8;
}

.activeleadbtn {
  background-Color: #fff;
  font-Size: 16;
  color: #FF4842;
  border: 1px solid #FF4842;
  padding: 10px 15px;
  font-weight: 700;
  border-radius: 8px;
  width: 33%;
}

.noactiveclass {
  background-Color: white;
  font-Size: 16;
  color: rgba(145, 158, 171, 0.8);
  border: 1px solid rgba(145, 158, 171, 0.24);
  font-weight: 700;
  padding: 10px 15px;
  font-weight: 700;
  border-radius: 8px;
  width: 33%;
}

.activeleadbtn1 {
  background-Color: #fff;
  font-Size: 16;
  color: #FF4842;
  border: 1px solid #FF4842;
  padding-Left: 20px;
  padding-right: 20px;
  padding-top: 9px;
  font-weight: 700;
  padding-bottom: 9px;
}

.noactiveclass1 {
  background-Color: white;
  font-Size: 16;
  color: rgba(145, 158, 171, 0.8);
  border: 1px solid rgba(145, 158, 171, 0.24);
  font-weight: 700;
  padding: 10px 15px;
  font-weight: 700;
  border-radius: 8px;
  width: 45%;
}

#sidebar ul.sidebar-menu li a.active i {
  color: #B72136 !important;
}

.tabletd_small tbody td {
  color: #000;
  font-size: 14px;
}

#sidebar ul.sidebar-menu li a + .collapse li {
  position: relative;
}

#sidebar ul.sidebar-menu li a + .collapse li a {
  color: #637381;
  font-size: 14px;
  font-weight: 600;
}

#sidebar ul.sidebar-menu li a + .collapse li a.active {
  background-color: transparent !important;
  color: #B72136;
}

#sidebar ul.sidebar-menu li a + .collapse li a:hover {
  background-color: transparent !important;
}

#sidebar ul.sidebar-menu li a + .collapse li a::after {
  content: "";
  position: absolute;
  left: 10px;
  width: 7px;
  height: 7px;
  background-color: #637381;
  border-radius: 15px;
  transition: all 0.5s;
}

#sidebar ul.sidebar-menu li a + .collapse li a.active::after {
  content: "";
  position: absolute;
  left: 10px;
  width: 7px;
  height: 7px;
  background-color: #637381;
  border-radius: 15px;
  transition: all 0.5s;
}

.cricle_arrow {
  border: 1px dotted #ccc;
  border-radius: 43px;
  font-size: 8px;
  width: 32px;
  height: 32px;
  text-align: center;
  line-height: 34px;
  cursor: pointer;
}

.cricle_arrow_left i,
.cricle_arrow i {
  font-size: 13px !important;
}

.cricle_arrow_left {
  border: 1px dotted #ccc;
  border-radius: 43px;
  font-size: 8px;
  width: 32px;
  height: 32px;
  text-align: center;
  line-height: 34px;
  cursor: pointer;
  transition: 0.5s ease;
  rotate: 180deg;
}

@media only screen and (min-width: 1200px) {
  .modal-container {
    max-width: 1000px;
  }
}/*# sourceMappingURL=main.css.map */